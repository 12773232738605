<template>
  <!-- Nav Start -->
  <div id="nav">
    <div class="navbar navbar-expand-md bg-light navbar-light">
      <a
        href="https://htmlcodex.com/product-landing-page-template"
        class="navbar-brand"
        >Drones</a
      >
      <button
        type="button"
        class="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarCollapse"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="collapse navbar-collapse justify-content-between"
        id="navbarCollapse"
      >
        <ul class="navbar-nav ml-auto">
          <li class="nav-item"><a href="#header" class="nav-link">Home</a></li>
          <li class="nav-item">
            <a href="#feature" class="nav-link">Features</a>
          </li>
          <li class="nav-item">
            <a href="#process" class="nav-link">Installation</a>
          </li>
          <li class="nav-item">
            <a href="#products" class="nav-link">Products</a>
          </li>
          <li class="nav-item">
            <a href="#testimonial" class="nav-link">Reviews</a>
          </li>
          <li class="nav-item"><a href="#faqs" class="nav-link">FAQs</a></li>
          <li class="nav-item">
            <a href="#contact" class="nav-link">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Nav End -->

  <!-- Header Start-->
  <div id="header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 d-md-none d-block">
          <div class="header-img">
            <img src="img/product-header.png" alt="Product Image" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="header-content">
            <h2>View The World<br />With <span>Drones</span></h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              viverra at massa sit amet ultricies
            </p>
            <a
              class="btn"
              href="https://htmlcodex.com/product-landing-page-template"
              >Buy Now</a
            >
          </div>
        </div>
        <div class="col-md-6 d-md-block d-none">
          <div class="header-img">
            <img src="img/product-header.png" alt="Product Image" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header End-->

  <!-- Mini Feature Start-->
  <div id="feature-mini">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-3 col-sm-6">
          <div class="feature-item">
            <i class="fa fa-camera"></i>
            <h3>Camera</h3>
            <p>30MP Camera</p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="feature-item">
            <i class="fa fa-battery-full"></i>
            <h3>Battery</h3>
            <p>2 Extra Battery</p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="feature-item">
            <i class="fa fa-tachometer-alt"></i>
            <h3>Speed</h3>
            <p>High Speed</p>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <div class="feature-item">
            <i class="fa fa-gamepad"></i>
            <h3>Control</h3>
            <p>Easy Controller</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Mini Feature End-->

  <!-- Feature Start-->
  <div id="feature">
    <div class="container">
      <div class="section-header">
        <p>Unique</p>
        <h1>Features</h1>
      </div>
      <div class="row align-items-center">
        <div class="col-md-4">
          <div class="product-feature">
            <div class="product-content">
              <h2>Easy to operate</h2>
              <p>
                Lorem ipsum dolor sit amet adipiscing elit ac eros at laoreet
              </p>
            </div>
            <div class="product-icon">
              <i class="fa fa-gamepad"></i>
            </div>
          </div>
          <div class="product-feature">
            <div class="product-content">
              <h2>Stable video output</h2>
              <p>
                Lorem ipsum dolor sit amet adipiscing elit ac eros at laoreet
              </p>
            </div>
            <div class="product-icon">
              <i class="fa fa-video"></i>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="product-img">
            <img src="img/product-features.png" alt="Product Image" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="product-feature">
            <div class="product-icon">
              <i class="fa fa-plug"></i>
            </div>
            <div class="product-content">
              <h2>Faster connection</h2>
              <p>
                Lorem ipsum dolor sit amet adipiscing elit ac eros at laoreet
              </p>
            </div>
          </div>
          <div class="product-feature">
            <div class="product-icon">
              <i class="fa fa-helicopter"></i>
            </div>
            <div class="product-content">
              <h2>Coordinated flight</h2>
              <p>
                Lorem ipsum dolor sit amet adipiscing elit ac eros at laoreet
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Feature End-->

  <!-- Newsletter Start-->
  <div id="newsletter">
    <img src="img/newsletter-bg.png" />
    <div class="container">
      <h2>Fly With Drones</h2>
      <form>
        <input type="email" placeholder="Email here" />
        <button class="btn">Submit</button>
      </form>
    </div>
  </div>
  <!-- Newsletter End-->

  <!-- Process Start-->
  <div id="process">
    <div class="container">
      <div class="section-header">
        <p>How To</p>
        <h1>Install</h1>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="process-col">
            <i class="fa fa-plug"></i>
            <h2>Connect</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit. In sed
              lorem efficitur vestibulum erat finibus
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="process-col">
            <i class="fa fa-sliders-h"></i>
            <h2>Configure</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit. In sed
              lorem efficitur vestibulum erat finibus
            </p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="process-col">
            <i class="fa fa-check"></i>
            <h2>Enjoy!!!</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipiscing elit. In sed
              lorem efficitur vestibulum erat finibus
            </p>
          </div>
        </div>
      </div>
      <div id="video-section">
        <div class="container">
          <div class="youtube-player" data-id="jssO8-5qmag"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- Process End-->

  <!-- Call To Action Start-->
  <div id="call-to-action">
    <div class="container">
      <h2>Call To Action</h2>
      <a class="btn" href="tel:+250789326245">Click Here To Call</a>
    </div>
    <img src="img/newsletter-bg.png" />
  </div>
  <!-- Call To Action End-->

  <!-- Products Start -->
  <div id="products">
    <div class="container">
      <div class="section-header">
        <p>Affordable</p>
        <h1>Price</h1>
      </div>
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-6">
          <div class="product-single">
            <div class="product-title">
              <h2>Drones Basic</h2>
              <p>20MP Camera</p>
            </div>
            <div class="product-img">
              <img src="img/product-1.png" alt="Product Image" />
            </div>
            <div class="product-footer">
              <h3>$199</h3>
              <a class="btn" href="#">Buy Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="product-single">
            <div class="product-title">
              <h2>Drones Silver</h2>
              <p>2 Extra Battery</p>
            </div>
            <div class="product-img">
              <img src="img/product-2.png" alt="Product Image" />
            </div>
            <div class="product-footer">
              <h3>$299</h3>
              <a class="btn" href="#">Buy Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="product-single">
            <div class="product-title">
              <h2>Drones Gold</h2>
              <p>Easy Controller</p>
            </div>
            <div class="product-img">
              <img src="img/product-3.png" alt="Product Image" />
            </div>
            <div class="product-footer">
              <h3>$399</h3>
              <a class="btn" href="#">Buy Now</a>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="product-single">
            <div class="product-title">
              <h2>Drones Platinum</h2>
              <p>30MP Camera</p>
            </div>
            <div class="product-img">
              <img src="img/product-4.png" alt="Product Image" />
            </div>
            <div class="product-footer">
              <h3>$499</h3>
              <a class="btn" href="#">Buy Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Products End -->
  <!-- FAQ Start -->
  <div id="faqs">
    <div class="container">
      <div class="section-header">
        <p>Quick</p>
        <h1>FAQs</h1>
      </div>
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="faqs-text">
            <h2>Freequently Asked Questions</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
              felis nisi, sagittis quis arcu eu, luctus cursus ante. Nullam nec
              diam vitae dolor porta ullamcorper. Duis condimentum ante urna.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
              felis nisi, sagittis quis arcu eu, luctus cursus ante. Nullam nec
              diam vitae dolor porta ullamcorper. Duis condimentum ante urna.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div id="accordion">
            <div class="card">
              <div class="card-header">
                <a
                  class="card-link"
                  data-toggle="collapse"
                  href="#collapseOne"
                  aria-expanded="true"
                >
                  Lorem ipsum dolor sit amet?
                </a>
              </div>
              <div
                id="collapseOne"
                class="collapse show"
                data-parent="#accordion"
              >
                <div class="card-body">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras
                  felis nisi, sagittis quis arcu eu, luctus cursus ante. Nullam
                  nec diam vitae dolor porta ullamcorper. Duis condimentum ante
                  urna, quis scelerisque velit bibendum eu.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <a class="card-link" data-toggle="collapse" href="#collapseTwo">
                  Maecenas libero magna accumsan quis?
                </a>
              </div>
              <div id="collapseTwo" class="collapse" data-parent="#accordion">
                <div class="card-body">
                  Maecenas libero magna, accumsan quis urna a, malesuada dictum
                  risus. Duis placerat interdum sodales. Vivamus nec dignissim
                  urna. Nulla tempor enim at viverra tempor.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <a
                  class="card-link"
                  data-toggle="collapse"
                  href="#collapseThree"
                >
                  Vivamus condimentum pharetra odio at efficitur?
                </a>
              </div>
              <div id="collapseThree" class="collapse" data-parent="#accordion">
                <div class="card-body">
                  Vivamus condimentum pharetra odio at efficitur. Pellentesque
                  condimentum erat a sapien maximus, sed tincidunt ante
                  consectetur. In pulvinar blandit sagittis. Fusce a lorem
                  eleifend, ornare sem eu, faucibus ligula.
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <a
                  class="card-link"
                  data-toggle="collapse"
                  href="#collapseFour"
                >
                  Mauris nec auctor sem?
                </a>
              </div>
              <div id="collapseFour" class="collapse" data-parent="#accordion">
                <div class="card-body">
                  Mauris nec auctor sem. In eu dui euismod, bibendum ex id,
                  congue enim. Suspendisse potenti. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FAQ Start -->
  <!-- Contact Start -->
  <div id="contact">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6">
          <div class="contact-info">
            <h2>Get in Touch</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              viverra at massa sit amet ultricies
            </p>
            <h3>
              <i class="fa fa-map-marker"></i>123 Drones Tower, Kigali,
              RWANDA
            </h3>
            <h3><i class="fa fa-envelope"></i>email@example.com</h3>
            <h3><i class="fa fa-phone"></i>0789326245</h3>
            <a class="btn" href="https://htmlcodex.com/contact">Contact Us</a>
            <div class="social">
              <a href="https://twitter.com/htmlcodex"
                ><i class="fab fa-twitter"></i
              ></a>
              <a href="https://facebook.com/htmlcodex"
                ><i class="fab fa-facebook-f"></i
              ></a>
              <a href=""><i class="fab fa-linkedin-in"></i></a>
              <a href=""><i class="fab fa-instagram"></i></a>
              <a href=""><i class="fab fa-youtube"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact Start -->
  <!-- Footer Start -->
  <div id="footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <p>
            &copy; Copyright <a href="#">Drones Skyline</a>. All
            Rights Reserved
          </p>
        </div>
        <div class="col-md-6">
          <p>Dev By <a href="#">Heritier Ganza</a></p>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer End -->
  <!-- Back to Top -->
  <a href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>
</template>
